<template>
    <div>
      <v-layout class="mainfont" wrap justify-center>
        <v-flex xs11 pt-4>
          <v-layout wrap>
            <v-flex xs12>
              <v-layout wrap pt-7 pb-10>
            <v-flex xs12>
              <v-card class="pa-4">
                <v-layout wrap>
                  <v-flex xs6 pb-3 class="heading">
                    <span style="font-size: 20px">
                      Application Number: {{ list.applicationNo }}</span
                    >
                  </v-flex>
                  <v-flex xs6 pb-3 class="heading">
                    <span style="font-size: 20px">
                      Application Date: {{ formatDate(list.created_at) }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>

              <v-card class="pb-4">
                <v-layout wrap>
                  <v-flex xs12 pl-3 pt-3 pb-4 style="background-color: black">
                    <span class="heading" style="font-size: 25px"
                      >Select Date and Duration</span
                    >
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap pb-3>
                      <v-flex xs12>
                        <v-divider></v-divider>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs4 text-left pl-3>
                    <span class="subheading" style="font-size: 16px">
                      District Name
                    </span>
                    <br />
                    <span>{{ list.district }}</span>
                  </v-flex>
                  <v-flex xs4 text-left>
                    <span class="subheading" style="font-size: 16px"> Date </span>
                    <br />
                    <span>
                      {{ formatDate(list.startDate) }}
                    </span>
                  </v-flex>
                  <v-flex xs4 text-left>
                    <span class="subheading" style="font-size: 16px">
                      Duration of Camp
                    </span>
                    <br />
                    <span>{{ list.campDuration }}</span
                    >&nbsp;
                    <span>Day</span>
                  </v-flex>
                  <!-- <v-flex xs12 text-left pt-7>
                      <v-layout wrap justify-center>
                        <v-flex xs8 text-center>
                          <span class="subheading" style="font-size: 16px;">
                            Selected camp  on
                            <span style="color:red">
                              {{ formatDate(list.endDate) }}
  
                            </span>
                            can accommodate people as follows
  
                          </span>
                        </v-flex>
  
                        <v-flex xs10 pt-3 pb-9>
                          <v-card elevation="0" style="border: 1px solid black; border-radius: 0px;">
                            <v-layout wrap pt-3 pb-3 justify-center>
                              <v-flex xs12>
                                <v-layout wrap justify-center>
                                  <v-flex xs3 pt-2 text-center>
                                    <span class="subheading">Staff/Group Leader</span>
                                  </v-flex>
                                  <v-flex pt-2 xs3 text-center>
                                    <span class="subheading">Male Members</span>
                                  </v-flex>
                                  <v-flex pt-2 xs3 text-center>
                                    <span class="subheading">Female Members</span>
                                  </v-flex>
                                  <v-flex pt-2 xs3 text-center>
                                    <span class="subheading">Transgender Members</span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12 pt-3 pb-3>
                                <v-divider></v-divider>
                              </v-flex>
                              <v-flex xs12>
                                <v-layout wrap justify-center>
                                  <v-flex v-if="list" pb-2 xs3 text-center>
                                    <span v-if="list.campid">
                                      <span v-if="list.campid.totalMembers">{{ list.campid.totalMembers }}</span>
  
                                    </span>
  
                                  </v-flex>
                                  <v-flex v-if="list" pb-2 xs3 text-center>
  
  
                                    <span v-if="list.campid">
                                      <span v-if="list.campid.membersLimit">
                                        <span v-if="list.campid.membersLimit.male">
                                          {{ list.campid.membersLimit.male }}
  
                                        </span>
  
                                      </span>
  
                                    </span>
  
  
                                  </v-flex>
                                  <v-flex v-if="list" pb-2 xs3 text-center>
  
  
                                    <span v-if="list.campid">
                                      <span v-if="list.campid.membersLimit">
                                        <span v-if="list.campid.membersLimit.male">
                                          {{ list.campid.membersLimit.male }}
  
                                        </span>
  
                                      </span>
  
                                    </span>
  
  
                                  </v-flex>
  
  
  
                                  <v-flex v-if="list" pb-2 xs3 text-center>
  
  
                                    <span v-if="list.campid">
                                      <span v-if="list.campid.membersLimit">
                                        <span v-if="list.campid.membersLimit.transgender">
                                          {{ list.campid.membersLimit.transgender }}
  
                                        </span>
  
                                      </span>
  
                                    </span>
  
                                  </v-flex>
  
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-flex> -->
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap pt-7>
            <v-flex xs12>
              <v-card>
                <v-layout wrap>
                  <v-flex xs12 pa-3 style="background-color: black">
                    <span style="font-size: 25px" class="heading"
                      >Applicant Details</span
                    >
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap pb-3>
                      <v-flex xs12>
                        <v-divider></v-divider>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs4 pl-3 pb-4 text-left>
                    <span class="subheading" style="font-size: 16px">
                      Total Number Of Participants
                    </span>
                    <br />
                    <span>{{ list.memberCount }}</span>
                  </v-flex>
                  <v-flex xs4 pl-3 pb-4 text-left>
                    <span class="subheading" style="font-size: 16px">
                      No. Of Males
                    </span>
                    <br />
                    <span>{{ list.maleCount }}</span>
                  </v-flex>
                  <v-flex xs4 pl-3 pb-4 text-left>
                    <span class="subheading" style="font-size: 16px">
                      No. Of Females
                    </span>
                    <br />
                    <span>{{ list.femaleCount }}</span>
                  </v-flex>
                  <v-flex xs4 pl-3 pb-4 text-left>
                    <span class="subheading" style="font-size: 16px">
                      No. Of Transgenders
                    </span>
                    <br />
                    <span>{{ list.transCount }}</span>
                  </v-flex>
                  
                  <v-flex xs4 text-left>
                    <span class="subheading" style="font-size: 16px">
                      Applicant Category
                    </span>
                    <br />
                    <span>{{ list.applicantCategory }}</span>
                  </v-flex>
                  <v-flex
                    xs4
                    text-left
                    v-if="list.applicantCategory === 'Students'"
                  >
                    <span class="subheading" style="font-size: 16px">
                      Student Category
                    </span>
                    <br />
                    <span>{{ list.studentCategory }}</span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap pt-7>
            <v-flex xs12>
              <v-card>
                <v-layout wrap>
                  <v-flex xs12 pa-3 style="background-color: black">
                    <span style="font-size: 25px" class="heading"
                      >Institution Details</span
                    >
                  </v-flex>
                  <v-flex xs12 pa-4>
                    <v-layout wrap>
                      <v-flex xs3 text-left>
                        <span class="subheading" style="font-size: 16px">
                          Name of the Institution/Organisation
                        </span>
                        <br />
                        <span>{{ list.instName }}</span>
                      </v-flex>
                      <v-flex xs3 text-left>
                        <span class="subheading" style="font-size: 16px">
                          Institution/Organisation code
                        </span>
                        <br />
                        <span>{{ list.instCode }}</span>
                      </v-flex>
                      <v-flex xs3 text-left>
                        <span class="subheading" style="font-size: 16px">
                          Type of Institution
                        </span>
                        <br />
                        <span>{{ list.instType }}</span>
                      </v-flex>
                      <v-flex xs3 text-left>
                        <span class="subheading" style="font-size: 16px">
                          Address of the Institution/Organisation
                        </span>
                        <br />
                        <span>{{ list.instAddress }}</span>
                      </v-flex>
                      <v-flex xs3 text-left pt-4>
                        <span class="subheading" style="font-size: 16px">
                          Institution Phone Number
                          <br />
                        </span>
                        <span>{{ list.instTeleNo }}</span>
                      </v-flex>
                      <v-flex xs3 text-left pt-4>
                        <span class="subheading" style="font-size: 16px">
                          Institution Mobile Number
                        </span>
                        <br />
                        <span>{{ list.instMobileNo }}</span>
                      </v-flex>
                      <v-flex xs3 text-left pt-4>
                        <span class="subheading" style="font-size: 16px">
                          Institution Email
                        </span>
                        <br />
                        <span>{{ list.instEmail }}</span>
                      </v-flex>
                      <v-flex xs3 text-left pt-4>
                        <span class="subheading" style="font-size: 16px">
                          Institution District
                        </span>
                        <br />
                        <span>{{ list.instDistrict }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap pt-7>
            <v-flex xs12>
              <v-card>
                <v-layout wrap>
                  <v-flex
                    style="background-color: black"
                    xs12
                    pa-3
                    class="heading"
                  >
                    <span style="font-size: 25px"
                      >Details of Head of Institution/Organisation</span
                    >
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap pb-3>
                      <v-flex xs12>
                        <v-layout wrap pa-3>
                          <v-flex xs3 text-left>
                            <span class="subheading" style="font-size: 16px">
                              Name of Head of Institution
                            </span>
                            <br />
                            <span>{{ list.instHeadName }}</span>
                          </v-flex>
                          <v-flex xs3 text-left>
                            <span class="subheading" style="font-size: 16px">
                              Designation in Institute /Organisation
                            </span>
                            <br />
                            <span>{{ list.headDesignation }}</span>
                          </v-flex>
                          <v-flex xs3 text-left>
                            <span class="subheading" style="font-size: 16px">
                              id card type
                            </span>
                            <br />
                            <span>{{ list.idCardType }}</span>
                          </v-flex>
                          <v-flex xs3 text-left>
                            <span class="subheading" style="font-size: 16px">
                              Id Card Number
                            </span>
                            <br />
                            <span>{{ list.idNo }}</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap pt-7>
            <v-flex xs12>
              <v-card>
                <v-layout wrap>
                  <v-flex xs12 pa-3 style="background-color: black">
                    <span style="font-size: 25px" class="heading"
                      >Group Leader/Teaching Staff Details
                    </span>
                  </v-flex>
                  <v-flex xs12 pa-5>
                    <v-card
                      elevation="0"
                      style="border: 1px solid black; border-radius: 0px"
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <v-layout wrap class="subheading">
                            <v-flex xs1 text-center pt-2> Name </v-flex>
                            <v-flex xs2 text-center pt-2> Designation </v-flex>
                            <v-flex xs2 text-center pt-2> Address </v-flex>
                            <v-flex xs1 text-center pt-2> Telephone </v-flex>
                            <v-flex xs2 text-center pt-2> Mobile Number </v-flex>
                            <v-flex xs2 text-center pt-2> Email </v-flex>
                            <v-flex xs2 text-center pt-2> Leader Gender </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12> </v-flex>
                      </v-layout>
                      <v-layout wrap pt-4 pb-4>
                        <v-flex xs12>
                          <v-divider></v-divider>
                        </v-flex>
                      </v-layout>
                      <v-layout
                        pb-3
                        wrap
                        v-for="(item, i) in leaderDetails"
                        :key="i"
                      >
                        <v-flex xs1 text-center pt-2>
                          {{ item.leaderName }}
                        </v-flex>
                        <v-flex xs2 text-center pt-2>
                          {{ item.leaderAddress1 }}
                        </v-flex>
                        <v-flex xs2 text-center pt-2>
                          {{ item.leaderAddress2 }}
                        </v-flex>
                        <v-flex xs1 text-center pt-2>
                          {{ item.telephone }}
                        </v-flex>
                        <v-flex xs2 text-center pt-2>
                          {{ item.leaderPhone }}
                        </v-flex>
                        <v-flex xs2 text-center pt-2>
                          {{ item.leaderEmail }}
                        </v-flex>
                        <v-flex xs2 text-center pt-2>
                          {{ item.leaderGender }}
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap pt-7>
            <v-flex xs12>
              <v-card>
                <v-layout wrap>
                  <v-flex xs12 pa-3 style="background-color: black">
                    <span style="font-size: 25px" class="heading">
                      Member Details
                    </span>
                  </v-flex>
                  <v-flex xs12 pa-4>
                    <v-card
                      elevation="0"
                      style="border: 1px solid black; border-radius: 0px"
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <v-layout wrap class="subheading">
                            <v-flex xs3 text-center pt-2> Name </v-flex>
                            <v-flex xs3 text-center pt-2> DOB </v-flex>
                            <v-flex xs3 text-center pt-2> Gender </v-flex>
                            <!-- <v-flex xs3 text-center pt-2>
                              Food Preferences
                            </v-flex> -->
                          </v-layout>
                        </v-flex>
                        <v-flex xs12> </v-flex>
                      </v-layout>
                      <v-layout wrap pt-4 pb-4>
                        <v-flex xs12>
                          <v-divider></v-divider>
                        </v-flex>
                      </v-layout>
  
                      <v-layout wrap v-for="(item, i) in memberDetails" :key="i">
                        <v-flex xs3 text-center pb-2>
                          {{ item.memberName }}
                        </v-flex>
                        <v-flex xs3 text-center pb-2>
                          {{ item.memberDob }}
                        </v-flex>
                        <v-flex xs3 text-center pb-2>
                          {{ item.memberGender }}
                        </v-flex>
                        <!-- <v-flex xs3 text-center pb-2>
                          {{ item.foodPreference }}
                        </v-flex> -->
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex pt-6 xs12 text-center>
              <v-card>
                <v-layout wrap>
                  <v-flex xs12 text-center>
                    <v-card>
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <template>
                            <div>
                              <v-layout wrap justify-center>
                                <v-flex
                                  text-left
                                  xs12
                                  pa-3
                                  style="background-color: black"
                                >
                                  <span style="font-size: 25px" class="heading"
                                    >Documents
                                  </span>
                                </v-flex>
                                <v-flex xs11 pb-6>
                                  <v-layout wrap justify-center>
                                    <v-flex xs6 pt-7 text-left>
                                      <label for="idProof">
                                        <span
                                          >ID proof of Head of institution /
                                          Organisation
                                          <span
                                            style="color: red; font-size: 23px"
                                            >*</span
                                          >
                                        </span>
                                      </label>
                                    </v-flex>
                                    <v-flex xs4 pt-6>
                                      <v-btn
                                        color="#3CB043"
                                        dark
                                        download
                                        :href="mediaURL + idProof"
                                        target="_blank"
                                        ><span
                                          style="
                                            text-transform: none;
                                            font-family: mainfont;
                                            font-size: 12px;
                                          "
                                          >View Pdf</span
                                        ></v-btn
                                      >
                                    </v-flex>
                                    <v-flex xs6 pt-9 text-left>
                                      <label for="reqLetter" text-left>
                                        <span
                                          >Request Letter from Head of
                                          Institution/ Organisation
                                          <span
                                            style="color: red; font-size: 23px"
                                            >*</span
                                          >
                                        </span>
                                      </label>
                                    </v-flex>
                                    <v-flex xs4 pt-9>
                                      <v-btn
                                        color="#3CB043"
                                        dark
                                        download
                                        :href="mediaURL + reqLetter"
                                        target="_blank"
                                        ><span
                                          style="
                                            text-transform: none;
                                            font-family: mainfont;
                                            font-size: 12px;
                                          "
                                          >View Pdf</span
                                        ></v-btn
                                      >
                                    </v-flex>
  
                                    <v-flex xs6 pt-9 text-left>
                                      <label for="participantfile" text-left>
                                        <span
                                          >List of participants duly signed by
                                          Head of Institution/ Organisation
                                          <span
                                            style="color: red; font-size: 23px"
                                            >*</span
                                          >
                                        </span>
                                      </label>
                                    </v-flex>
                                    <v-flex xs4 pt-9>
                                      <v-btn
                                        color="#3CB043"
                                        dark
                                        download
                                        :href="
                                          mediaURL + participantfile
                                        "
                                        target="_blank"
                                        ><span
                                          style="
                                            text-transform: none;
                                            font-family: mainfont;
                                            font-size: 12px;
                                          "
                                          >View Pdf</span
                                        ></v-btn
                                      >
                                    </v-flex>
                                    <v-flex xs6 pt-9 text-left>
                                      <label for="leaderIdProof" text-left>
                                        <span
                                          >ID Proof of one of the Group
                                          Leader/Teaching staff
                                          <span
                                            style="color: red; font-size: 23px"
                                            >*</span
                                          >
                                        </span>
                                      </label>
                                    </v-flex>
                                    <v-flex xs4 pt-9>
                                      <v-btn
                                        color="#3CB043"
                                        dark
                                        download
                                        :href="
                                          mediaURL + leaderIdProof
                                        "
                                        target="_blank"
                                        ><span
                                          style="
                                            text-transform: none;
                                            font-family: mainfont;
                                            font-size: 12px;
                                          "
                                          >View Pdf</span
                                        ></v-btn
                                      >
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </div>
                          </template>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap pt-7 pb-10>
            <v-flex xs12>
              <v-card class="pa-4">
                <v-layout wrap>
                  <v-flex xs6 pb-3 class="heading">
                    <span style="font-size: 20px">
                      Application Number: {{ list.applicationNo }}</span
                    >
                  </v-flex>
                  <v-flex xs6 pb-3>
                    <v-btn color="blue" @click="$router.push('/records?id=' + list._id)">
                      <span
                        style="
                          text-transform: none;
                          font-family: mainfont;
                          font-size: 15px;
                          color: white;
                        "
                        >VIEW TIMELINE</span
                      ></v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-start>
  <v-flex xs3 pb-3>
    <v-btn color="primary" @click="goBack">
  <span style="text-transform: none; font-size: 15px; color: white;">
    GO BACK
  </span>
</v-btn>
  </v-flex>
</v-layout>

        </v-flex>
      </v-layout>
    </div>
  </template>
  
  <script>
  import axios from "axios";
import CryptoJS from "crypto-js";

  export default {
    components: {},
    data() {
      const today = new Date().toISOString().substr(0, 10);
      return {
        memberdetails: [],
        memberDetails: [],
        leaderName: null,
        leaderAddress1: null,
        leaderAddress2: null,
        telephone: null,
        idCardType: null,
        leaderPhone: null,
        leaderGender: null,
        leaderEmail: null,
        district: null,
        instHeadName: null,
        headDesignation: null,
        idCardTypeidCardType: null,
        idNo: null,
        startDate: today,
        showSnackBar: false,
        isChecked: false,
        appLoading: false,
        msg: "",
        expandSection: false,
        name: null,
        barlist: [],
        list: {},
        selectedDistrict: null,
        campDuration: "",
        menu1: false,
        male: null,
        memberCount: null,
        leaderdetails: [],
        idProof: "",
        reqLetter: "",
        participantfile: "",
        leaderIdProof: "",
        leaderDetails: [],
        applicantCategory: "",
        applicantname: null,
        applicantcategorys: [
          "Students",
          "Press Reporters",
          "LSG Members",
          "NGO youth/charitable organization",
        ],
      };
    },
    mounted() {
      this.getList();
    },
    methods: {
      goBack() {
  // Use the router to navigate back to the previous page
  this.$router.go(-1);
},
      formatDate(item) {
        var dt = new Date(item);
        var year = dt.getFullYear();
        dt = dt.toString();
        var strTime = dt.slice(4, 10) + " " + year;
        return strTime;
      },
      getList() {
        const encryptedId = this.$route.query.id;
  const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
        axios({
          method: "POST",
          url: "/single/application",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            appid: decryptedId,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.list = response.data.data;
              this.leaderDetails = this.list.leaderDetails;
              this.memberDetails = this.list.memberDetails;
              this.idProof = this.list.idProof;
              this.reqLetter = this.list.reqLetter;
              this.participantfile = this.list.participantfile;
              this.leaderIdProof = this.list.leaderIdProof;
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.ServerError = true;
            console.error(err);
          });
      },
    },
  };
  </script>
  
  
  <style>
  .subheading {
    font-family: mainfont;
    color: rgb(13, 154, 69);
    text-transform: none;
    font-size: 17px;
  }
  
  .heading {
    font-family: mainfont;
    color: rgb(229, 111, 8);
    text-transform: none;
    font-size: 25px;
  }
  </style>